<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="this.pageTitle"
      :subtitle="this.pageSubTitle"
      :hide-buttons="true"
    >
      <vs-divider class="vx-col w-full mt-5" position="left" color="light">Datos Generales</vs-divider>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="title" class="w-full select-large">Título</label>
          <vs-input
            id="title" name="title" v-model="entryModel.settings.title" v-validate="'required'" class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("title") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="description" class="w-full select-large">Descripción</label>
          <editor
            id="description" name="description"
            :content.sync="entryModel.settings.description.html" :resourceType="resource"
          />
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-5">
          <vs-divider class="vx-col text-right" position="right" color="light"><b>Desktop</b></vs-divider>
          <uploader
            :classFile="'file-upload-1'"
            :maxNumberOfFiles="1"
            @files-removed="removedFiles('desktop')"
            @files-updated="desktopUpload"
            :downloadedFiles="entryModel.settings.desktop"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mb-5">
          <vs-divider class="vx-col text-right" position="right" color="light"><b>Mobile</b></vs-divider>
          <uploader
            :classFile="'file-upload-2'"
            :maxNumberOfFiles="1"
            @files-removed="removedFiles('mobile')"
            @files-updated="mobileUpload"
            :downloadedFiles="entryModel.settings.mobile"
          />
        </div>
      </div>

      <div v-if="isCarousel">
        <vs-divider class="vx-col w-full mt-5" position="left" color="light">Call to Action</vs-divider>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <label for="button_label" class="w-full select-large">Texto</label>
            <vs-input
              id="button_label" name="button_label" v-model="entryModel.settings.button.label"
              class="w-full" autocomplete="off"
            />
            <span class="text-danger">{{ errors.first("button_label") }}</span>
            <br/>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <label for="button_link" class="w-full select-large">Enlace</label>
            <vs-input
              id="button_link" name="button_link" v-model="entryModel.settings.button.link"
              class="w-full" autocomplete="off"
            />
            <span class="text-danger">{{ errors.first("button_link") }}</span>
            <br/>
          </div>
        </div>

        <vs-divider class="vx-col w-full mt-5" position="left" color="light">Navigation button</vs-divider>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <label for="navigation_button_label" class="w-full select-large">Texto</label>
            <vs-input
              id="navigation_button_label" name="navigation_button_label"
              v-model="entryModel.settings.navigation_button.label" v-validate="'required'"
              class="w-full" autocomplete="off"
            />
            <span class="text-danger">{{ errors.first("navigation_button_label") }}</span>
            <br/>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <label for="navigation_button_icon" class="w-full select-large">
              Icon class (<a href="/icons" target="_blank">Ver Listado de Icons</a>)
            </label>
            <vs-input
              id="navigation_button_icon" name="navigation_button_icon"
              v-model="entryModel.settings.navigation_button.icon_class" v-validate="'required'"
              class="w-full" autocomplete="off"
            />
            <span class="text-danger">{{ errors.first("navigation_button_icon") }}</span>
            <br/>
          </div>
        </div>

        <vs-divider class="vx-col w-full" position="left" color="light">Publicación</vs-divider>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <label for="approved" class="w-full select-large">Aprobado</label>
            <vs-select
              id="approved" name="approved" v-model="entryModel.approved" v-validate="'required'"
              class="w-full select-large"
            >
              <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
              <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
            </vs-select>
            <span class="text-danger">{{ errors.first("approved") }}</span>
            <br/>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <label for="priority" class="w-full select-large">Prioridad</label>
            <vs-input
              type="number" id="priority" name="priority" min="1" max="10"
              v-model="entryModel.priority" v-validate="'required'"
              class="w-full"
            />
            <span class="text-danger">{{ errors.first("priority") }}</span>
            <br/>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/2 w-full">
            <label for="publish_at" class="w-full select-large">Fecha publicación</label>
            <flat-pickr
              :config="configdateTimePicker"
              id="publish_at" name="publish_at" placeholder="Fecha y hora de publicación"
              v-model="entryModel.publish_at"
              class="w-full flatpickr flatpickr-input"
            />
            <br/>
          </div>

          <div class="vx-col md:w-1/2 w-full">
            <label for="expire_at" class="w-full select-large">Fecha expiración</label>
            <flat-pickr
              :config="configdateTimePicker"
              id="expire_at" name="expire_at" placeholder="Fecha y hora de expiración"
              v-model="entryModel.expire_at"
              class="w-full flatpickr flatpickr-input"
            />
            <br/>
          </div>
        </div>
      </div>


      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="backToSlidesList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Configurations from "./../../utils/configurations";
  import Upload from "./../../utils/upload";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import Editor from "./../../components/Editor.vue";
  import Builder from "./../../components/Builder.vue";
  import Uploader from "./../../components/Uploader.vue";
  import {Validator} from "vee-validate";

  let _ = require("lodash");
  let notifier = new Notifier();
  let configurations = new Configurations();
  const ENTRY_CLIENT = new EntriesClient("media-objects");
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");
  const UPLOAD = new Upload("resource");

  let requiredGeneralDataFields = ["institution", "name", "description", "featured", "priority", "approved"];
  let requiredGeneralDataFieldsCustomMessages = requiredGeneralDataFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: requiredGeneralDataFieldsCustomMessages});

  export default {
    data() {
      return {
        pageTitle: "Slide",
        pageSubTitle: "Formulario de creación y actualización de slides",
        isCarousel: this.$route.params.type.includes("carousel"),
        configdateTimePicker: configurations.configDatePicker(),
        resource: "media-objects",
        institutions: [],
        entryModel: {
          name: "",
          institution: this.$route.params.institution === "bank" ? {
            id: "bank",
            code: "banco",
            name: "Banco",
            slug: "banco"
          } : {
            id: "insurances",
            code: "seguros",
            name: "Seguros",
            slug: "seguros"
          },
          type: this.$route.params.type,
          settings: {
            mobile: {
              url: null,
              meta: {
                title: null,
                description: null,
              },
            },
            desktop: {
              url: null,
              meta: {
                title: null,
                description: null,
              },
            },
            navigation_button: {
              label: null,
              icon_class: null,
            },
            title: null,
            description: {
              html: null
            },
            button: {
              label: null,
              link: null,
            }
          },
          approved: false,
          priority: 10,
          publish_at: null,
          expire_at: null
        }
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id)
          .then(() => {
            this.$vs.loading.close();
          });
      }
    },

    computed: {
      requiredGeneralDataFieldsFilled() {
        return !this.errors.any("general-data")
          && this.entryModel.priority !== null;
      }
    },

    methods: {
      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            _.merge(this.entryModel, response.data);
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.$vs.loading();
        this.cleanModel();

        this.entryModel.name = `Slide #${this.entryModel.priority}`;

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.backToSlidesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        } else {
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.backToSlidesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      async desktopUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.settings.desktop.url = response;
              this.entryModel.settings.desktop.meta.title = files[0].meta.name;
              this.entryModel.settings.desktop.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
          this.$vs.loading.close();
        }
      },

      async mobileUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.settings.mobile.url = response;
              this.entryModel.settings.mobile.meta.title = files[0].meta.name;
              this.entryModel.settings.mobile.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
          this.$vs.loading.close();
        }
      },

      async removedFiles(file) {
        this.entryModel.settings[file] = {
          url: null,
          meta: {
            title: null,
            description: null
          }
        };
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })

        if (!this.entryModel.settings.button.link && !this.entryModel.settings.button.label) {
          delete this.entryModel.settings.button;
        }
      },

      backToSlidesList() {
        this.$router.push({
          name: "media-object-slides",
          params: {
            institution: this.$route.params.institution,
            type: this.$route.params.type,
          }
        });
      },
    },
    components: {
      Editor,
      FormWizard,
      TabContent,
      flatPickr,
      Builder,
      Uploader,
      "v-select": vSelect
    }
  };
</script>