import { Spanish } from 'flatpickr/dist/l10n/es.js';

export default class Configurations {
  
  configDatePicker() {
    return {
      enableTime: true,
      dateFormat: "Z",
      altInput: true,
      altFormat: "Y-m-d h:i K",
      locale: Spanish,
    }
  }
}