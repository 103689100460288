import axios from 'axios';

let qs = require('qs');

export default class CoreClient {
  constructor(type) {
    this.type = type;
  }

  async all({queryStrings} = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}`;
    return axios({
      url: url,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }
}
